<template>
  <div class="example-container">
    <div class="head">
      <el-form inline :model="form">
        <el-form-item label="示例id">
          <el-input ref="input" v-model="form.id"></el-input>
        </el-form-item>
        <el-form-item label="任务id">
          <el-input ref="input" v-model="form.job_id"></el-input>
        </el-form-item>
        <el-form-item label="上线状态">
          <el-select v-model="form.status" placeholder="请选择">
            <el-option label="所有" :value="null"></el-option>
            <el-option
              v-for="item in ONLINE_STATUS"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分类">
          <el-select v-model="form.source" placeholder="请选择">
            <el-option label="所有" :value="null"></el-option>
            <el-option
              v-for="item in SOURCES"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList">筛选</el-button>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="addExample">新建示例</el-button>
    </div>
    <el-table
      stripe
      border
      v-loading="loading"
      :data="list"
      style="width: 100%"
      height="calc(100% - 95px )"
      @row-dblclick="editTask"
      @sort-change="sortChange"
    >
      <el-table-column label="序号" type="index" width="50"></el-table-column>
      <el-table-column
        prop="id"
        label="示例ID"
        align="center"
        width="80"
      ></el-table-column>
      <el-table-column
        prop="headUrl"
        label="发布人头像"
        align="center"
        width="120"
      >
        <template slot-scope="scope">
          <img style="width: 100%" :src="scope.row.headUrl" />
        </template>
      </el-table-column>
      <el-table-column
        prop="loverHeadUrl"
        label="另一半头像"
        align="center"
        width="120"
      >
        <template slot-scope="scope">
          <img style="width: 100%" :src="scope.row.loverHeadUrl" />
        </template>
      </el-table-column>
      <el-table-column
        prop="userName"
        label="发布人昵称"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="loverName"
        label="另一半昵称"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column label="发布内容" align="center">
        <template slot-scope="scope">
          <div class="content-column">
            <span>{{ scope.row.content }}</span>
            <img
              v-if="scope.row.pic"
              style="height: 80px"
              :src="
                ((JSON.parse(scope.row.pic).photos || [])[0] || {}).main_url
              "
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="jobId" label="任务ID" align="center" width="80">
      </el-table-column>
      <el-table-column
        prop="jobTitle"
        label="任务标题"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="source"
        label="分类"
        align="center"
        width="100"
        :formatter="sourceFormatter"
      >
      </el-table-column>
      <el-table-column
        prop="weight"
        label="权重"
        align="center"
        width="100"
        sortable="custom"
      >
      </el-table-column>
      <el-table-column
        prop="status"
        label="上线状态"
        align="center"
        width="100"
        :formatter="statusFormatter"
      >
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNo"
      :page-sizes="[10, 20, 30, 40, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <el-dialog
      v-loading="dialogLoading"
      class="taskadd-dialog"
      center
      :title="dialogType === 'add' ? '新建示例' : '编辑示例'"
      :visible.sync="dialogVisible"
      width="500px"
      @close="beforeDialogClose"
    >
      <el-form
        :model="editform"
        ref="editform"
        label-width="100px"
        size="small"
        :rules="rules"
      >
        <el-form-item label="示例ID" prop="id">
          <el-input
            placeholder="自动生成不可更改"
            v-model="editform.id"
            readonly
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="任务ID" prop="job_id">
          <el-input
            placeholder="请输入任务ID"
            v-model.number="editform.job_id"
            type="number"
            :disabled="!editable"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="用户昵称" prop="name" class="name-item">
          <el-form
            :model="editform.name"
            ref="nameform"
            size="small"
            inline
            :rules="nameRules"
          >
            <el-form-item prop="user_name">
              <el-input
                :disabled="!editable"
                v-model="editform.name.user_name"
                placeholder="发布人昵称"
              ></el-input>
            </el-form-item>
            <el-form-item prop="lover_name">
              <el-input
                :disabled="!editable"
                v-model="editform.name.lover_name"
                placeholder="另一半昵称"
              ></el-input>
            </el-form-item>

            <el-button
              :disabled="!editable"
              type="text"
              @click="openSelectDialog('nickname')"
              >选择昵称</el-button
            >
          </el-form>
        </el-form-item>
        <el-form-item label="用户头像" prop="avatar" class="avatar-item">
          <div>
            <photo-upload
              :disabled="!editable"
              v-model="editform.avatar.head_url"
              :size="80"
              ref="head-upload"
            ></photo-upload>
            <span>发布人头像</span>
          </div>
          <div>
            <photo-upload
              v-model="editform.avatar.lover_head_url"
              :size="80"
              :disabled="!editable"
              ref="lover-head-upload"
            ></photo-upload>
            <span>另一半头像</span>
          </div>
          <el-button
            :disabled="!editable"
            type="text"
            @click="openSelectDialog('avatar')"
            >选择头像</el-button
          >
        </el-form-item>
        <el-form-item label="发布内容" prop="content">
          <el-input
            type="textarea"
            :disabled="!editable"
            :autosize="{ minRows: 3, maxRows: 6 }"
            placeholder="请输入内容"
            v-model="editform.content"
            maxlength="800"
            show-word-limit
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="发表图片" prop="img">
          <photo-upload
            :disabled="!editable"
            v-model="editform.img"
            ref="img-upload"
          ></photo-upload>
        </el-form-item>
        <el-form-item label="权重" prop="weight">
          <el-input
            :disabled="!editable"
            placeholder="请输入权重"
            v-model.number="editform.weight"
            type="number"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="上线状态" prop="status">
          <el-select
            v-model="editform.status"
            placeholder="请选择上线状态"
            class="state-select"
          >
            <el-option
              v-for="item in ONLINE_STATUS"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveExample">保 存</el-button>
      </span>
    </el-dialog>
    <el-dialog
      center
      title="头像"
      :visible.sync="avatar.dialogVisible"
      width="500px"
    >
      <el-table
        stripe
        :data="avatar.list"
        style="width: 100%"
        height="500px"
        :show-header="false"
        v-loading="avatar.loading"
        @row-click="avatarClick"
      >
        <el-table-column align="center">
          <template slot-scope="scope">
            <img style="height: 80px" :src="scope.row.left_url" />
          </template>
        </el-table-column>
        <el-table-column align="center">
          <template slot-scope="scope">
            <img style="height: 80px" :src="scope.row.right_url" />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange($event, 'avatar')"
        :current-page="avatar.page"
        :page-sizes="[avatar.rows]"
        :page-size="avatar.rows"
        layout="total, pager, jumper"
        :total="avatar.total"
      >
      </el-pagination>
    </el-dialog>
    <el-dialog
      center
      title="昵称"
      :visible.sync="nickname.dialogVisible"
      width="500px"
    >
      <el-table
        stripe
        border
        :data="nickname.list"
        style="width: 100%"
        height="500px"
        :show-header="false"
        v-loading="nickname.loading"
        @row-click="nicknameClick"
      >
        <el-table-column align="center" prop="name_a"> </el-table-column>
        <el-table-column align="center" prop="name_b"> </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange($event, 'nickname')"
        :current-page="nickname.page"
        :page-sizes="[nickname.rows]"
        :page-size="nickname.rows"
        layout="total, pager, jumper"
        :total="nickname.total"
      >
      </el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import {
  uploadPhoto,
  exampleList,
  avatarList,
  nicknameList,
  exampleCreation,
  exampleModification
} from '@/api/loveAdvice';
import { ONLINE_STATUS, SOURCES } from './constant';
import PhotoUpload from './components/photo-upload.vue';
export default {
  data () {
    const rules = {
      title: [{ required: true, trigger: 'blur', message: '标题不能为空' }],
      job_id: [{ required: true, trigger: 'blur', message: '任务id不能为空' }],
      name: [
        {
          type: 'object',
          required: true
        }
      ],
      weight: [{ required: true, trigger: 'blur', message: '权重不能为空' }],
      status: [
        { required: true, trigger: 'change', message: '上线状态不能为空' }
      ],
      content: [
        [
          {
            validator: (rule, value, callback) => {
              console.log(value);
              if (!(this.editform.img || value)) {
                callback('图片和内容至少一项不能为空');
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ]
      ],
      avatar: [
        {
          type: 'object',
          required: true,
          fields: {
            head_url: {
              type: 'object',
              required: true,
              trigger: 'change',
              message: '用户头像不能为空'
            },
            lover_head_url: {
              type: 'object',
              required: true,
              trigger: 'change',
              message: '另一半头像不能为空'
            }
          }
        }
      ],
      img: [
        {
          validator: (rule, value, callback) => {
            if (!(this.editform.content || value)) {
              callback('图片和内容至少一项不能为空');
            } else {
              callback();
            }
          },
          trigger: 'change'
        }
      ]
    };
    const nameRules = {
      user_name: [
        { required: true, trigger: 'blur', message: '发布人昵称不能为空' }
      ],
      lover_name: [
        { required: true, trigger: 'blur', message: '另一半昵称不能为空' }
      ]
    };
    return {
      rules,
      nameRules,
      order: null,
      ONLINE_STATUS,
      SOURCES,
      loading: false,
      pageSize: 10,
      pageNo: 1,
      total: 0,
      editable: true,
      dialogVisible: false,
      dialogType: 'add',
      form: {
        id: null,
        job_id: null,
        source: null,
        status: null
      },
      list: [],
      dialogLoading: false,
      editform: {
        id: null,
        job_id: null,
        content: null,
        name: {
          user_name: null,
          lover_name: null
        },
        avatar: {
          head_url: null,
          lover_head_url: null
        },
        img: null,
        weight: 0,
        status: null
      },
      avatar: {
        page: 1,
        rows: 10,
        list: [],
        total: 0,
        dialogVisible: false,
        loading: false
      },
      nickname: {
        page: 1,
        rows: 10,
        list: [],
        total: 0,
        loading: false,
        dialogVisible: false
      }
    };
  },
  created () {
    this.getList();
    this.getAvatarList();
    this.getNicknameList();
  },
  computed: {
    uploadStateText () {
      const map = new Map();
      map.set(2, '图片待上传');
      map.set(3, '图片上传中...');
      map.set(4, '图片上传成功！');
      map.set(5, '图片上传失败！');
      return {
        user: map.get(this.uploadState.user) || '',
        lover: map.get(this.uploadState.lover) || ''
      };
    }
  },
  components: {
    PhotoUpload
  },
  methods: {
    openSelectDialog (type) {
      console.log(type);
      if (this[type].page !== 1) {
        this[type].page = 1;
        this.getDialogList(type);
      }
      this[type].dialogVisible = true;
    },
    nicknameClick (row) {
      this.editform.name = {
        user_name: row.name_a,
        lover_name: row.name_b
      };
      this.nickname.dialogVisible = false;
    },
    avatarClick (row) {
      this.editform.avatar = {
        head_url: { main_url: row.left_url },
        lover_head_url: { main_url: row.right_url }
      };
      this.avatar.dialogVisible = false;
    },
    getDialogList (type) {
      if (type === 'avatar') {
        this.getAvatarList();
      } else {
        this.getNicknameList();
      }
    },
    async getAvatarList () {
      this.avatar.loading = true;
      try {
        const resp = await avatarList({
          page: this.avatar.page,
          rows: this.avatar.rows
        });
        console.log('avatarlist resp:', resp);
        if (resp.result !== 1) {
          throw new Error(JSON.stringify(resp));
        }
        this.avatar.list = resp.rows;
        this.avatar.total = resp.total;
      } catch (error) {
        console.error(error);
        this.$message({
          type: 'error',
          message: '拉取头像列表失败'
        });
      }
      this.avatar.loading = false;
    },
    async getNicknameList () {
      this.nickname.loading = true;
      try {
        const resp = await nicknameList({
          page: this.nickname.page,
          rows: this.nickname.rows
        });
        console.log('nicknamelist resp:', resp);
        if (resp.result !== 1) {
          throw new Error(JSON.stringify(resp));
        }
        this.nickname.list = resp.rows;
        this.nickname.total = resp.total;
      } catch (error) {
        console.error(error);
        this.$message({
          type: 'error',
          message: '拉取昵称列表失败'
        });
      }
      this.nickname.loading = false;
    },
    beforeDialogClose () {
      this.$refs['img-upload'].resetFile();
      this.$refs['head-upload'].resetFile();
      this.$refs['lover-head-upload'].resetFile();
      this.$refs.editform.resetFields();
      this.$refs.nameform.resetFields();
      this.editform = {
        id: null,
        job_id: null,
        content: null,
        name: {
          user_name: null,
          lover_name: null
        },
        avatar: {
          head_url: null,
          lover_head_url: null
        },
        img: null,
        weight: 0,
        status: null
      };
      this.dialogType = '';
      this.editable = true;
    },

    sortChange ({ prop, order }) {
      const orderBy = {
        ascending: 'asc',
        descending: 'desc'
      };
      this.order = order ? `${prop} ${orderBy[order]}` : null;
      this.getList();
    },
    sourceFormatter (row, column, cellValue, index) {
      return SOURCES.find((i) => i.value === cellValue).label;
    },
    statusFormatter (row, column, cellValue, index) {
      return ONLINE_STATUS.find((i) => i.value === cellValue).label;
    },
    saveExample () {
      if (this.dialogLoading) {
        return;
      }
      this.dialogLoading = true;

      this.$refs.editform.validate((valid) => {
        if (valid) {
          this.$refs.nameform.validate(async (nameValid) => {
            if (nameValid) {
              try {
                console.log('form:', this.editform);
                let data = {
                  job_id: this.editform.job_id,
                  weight: this.editform.weight,
                  status: this.editform.status,
                  content: this.editform.content,
                  user_name: this.editform.name.user_name,
                  lover_name: this.editform.name.lover_name,
                  head_url: this.editform.avatar.head_url.main_url,
                  lover_head_url: this.editform.avatar.lover_head_url.main_url
                };
                if (this.editform.img) {
                  data.pic = JSON.stringify({
                    photos: [this.editform.img]
                  });
                }
                if (this.dialogType === 'edit') {
                  data.id = this.editform.id;
                }
                const resp =
                  this.dialogType === 'add'
                    ? await exampleCreation(data)
                    : await exampleModification(data);
                console.log('examplesave resp:', resp);
                if (resp.result !== 1) {
                  throw new Error(JSON.stringify(resp));
                }
                this.$message({
                  type: 'success',
                  message: `示例${
                    this.dialogType === 'add' ? '新建' : '编辑'
                  }成功`
                });
                this.dialogVisible = false;
                this.getList();
              } catch (error) {
                console.error(error);
                this.$message({
                  type: 'error',
                  message: `示例${
                    this.dialogType === 'add' ? '新建' : '编辑'
                  }失败`
                });
              }
            }

            this.dialogLoading = false;
          });
        } else {
          this.dialogLoading = false;
        }
      });
    },
    deleteImg (type) {
      this.uploadState[type] = 0;
      this.files[[type]] = null;
      this.templeUrl[[type]] = null;
      const config = {
        user: 'head_url',
        lover: 'lover_head_url'
      };
      if (this.editform.avatar[config[type]]) {
        this.editform.avatar[config[type]] = null;
      }
    },
    addExample () {
      this.dialogType = 'add';
      this.dialogVisible = true;
    },
    editTask (row) {
      this.dialogType = 'edit';
      this.editform = {
        id: row.id,
        job_id: row.jobId,
        content: row.content,
        name: {
          user_name: row.userName,
          lover_name: row.loverName
        },
        avatar: {
          head_url: { main_url: row.headUrl },
          lover_head_url: { main_url: row.loverHeadUrl }
        },
        img: row.pic ? JSON.parse(row.pic).photos[0] : null,
        weight: row.weight,
        status: row.status
      };
      if (row.status === 1) {
        this.editable = false;
      }
      this.dialogVisible = true;
    },
    async uploadFile (type) {
      this.uploadState[type] = 3;
      try {
        const resp = await uploadPhoto(this.files[type]);
        console.log('taglist resp:', resp);
        if (resp.data.result !== 1) {
          throw new Error(resp.data.error_msg);
        }
        const config = {
          user: 'head_url',
          lover: 'lover_head_url'
        };
        this.editform.avatar[config[type]] = JSON.stringify({
          photos: [
            {
              tiny_url: resp.data.tiny_url,
              photo_id: resp.data.photo_id,
              width: resp.data.width,
              main_url: resp.data.main_url,
              large_url: resp.data.large_url,
              height: resp.data.height
            }
          ]
        });
        this.uploadState[type] = 4;
      } catch (error) {
        this.uploadState[type] = 5;
        console.error(error);
        this.$message({
          type: 'error',
          message: error
        });
      }
    },
    openFile (type) {
      const that = this;
      that.uploadState[type] = 1;
      let parent = this.$refs[`upload-${type}`];
      let input = document.createElement('input');
      input.style = 'display:none;';
      input.type = 'file';
      input.accept = 'image/*';
      input.addEventListener(
        'change',
        function () {
          let fileList = this.files;
          if (fileList.length > 0) {
            let file = fileList[0];
            that.files[type] = file;
            let reader = new FileReader(); // 创建FileReader对象实例reader
            reader.readAsDataURL(file); // 将图片url转换为base64码
            reader.onload = function () {
              that.templeUrl[type] = this.result;
              that.uploadState[type] = 2;
              reader = null;
            };
          }
          parent.appendChild(input);
          parent.removeChild(input);
          input = null;
          parent = null;
        },
        { once: true }
      );
      input.click();
    },
    handleSizeChange (size) {
      if (size) {
        this.pageSize = size;
        this.pageNo = 1;
        this.getList();
      }
    },
    handleCurrentChange (current, type) {
      console.log(arguments);
      if (current) {
        if (type) {
          this[type].page = current;
          this.getDialogList(type);
        } else {
          this.pageNo = current;
          this.getList();
        }
      }
    },
    async getList () {
      console.log(this.form);
      this.loading = true;
      try {
        let data = {
          source: this.form.source,
          id: this.form.id,
          job_id: this.form.job_id,
          order: this.order,
          page: this.pageNo,
          count: this.pageSize,
          status: this.form.status
        };
        const resp = await exampleList(data);
        console.log('tasklist resp:', resp);
        if (resp.result !== 1) {
          throw new Error(JSON.stringify(resp));
        }
        this.total = resp.total;
        this.list = resp.list;
      } catch (error) {
        console.error(error);
        this.$message({
          type: 'error',
          message: '拉取任务列表失败'
        });
      }
      this.loading = false;
    }
  }
};
</script>
<style lang="scss">
.example-container {
  background: #fff;
  width: calc(100% - 24px);
  height: calc(100vh - 50px - 12px - 24px);
  padding: 22px;
  box-sizing: border-box;
  margin: 12px;

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding-bottom: 12px;
    .el-form-item {
      margin-bottom: 0;
    }
  }
  .content-column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .el-pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
}
.taskadd-dialog {
  .state-select {
    width: 100%;
  }
  .avatar-item {
    .el-form-item__content {
      display: flex;
      align-items: center;
      & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        & > span {
          // color: #f74769;
          font-size: 12px;
        }
        & + div {
          margin: 0 30px;
        }
      }
    }
  }
  .name-item {
    margin-bottom: 0;
    .el-form-item__content {
      display: flex;
      align-items: center;
      .el-form {
        width: 100%;
        display: flex;
        .el-button {
          margin-bottom: 18px;
        }
      }
    }
  }
}
</style>